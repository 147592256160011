<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

import TablaLista from "../../components/tablaLista.vue";
export default {
  data() {
    return {
      nombre: "",
      correo: "",
      rol: "",
      division: "",
      sucursal: "",
      listaSucursalesSk: [
        { nombre: "SK LAS MARINAS", division: 7104 },
        { nombre: "SK LERMA", division: 7105 },
        { nombre: "SK TOLLOCAN", division: 7120 },
        { nombre: "SK PINO SUAREZ", division: 7121 },
        { nombre: "SK TENANCINGO", division: 7122 },
        { nombre: "SK SAN BUENA VENTURA", division: 7125 },
        { nombre: "SK CENTRO", division: 7126 },
        { nombre: "SK LA ASUNCION", division: 7127 },
        { nombre: "SK SANTIAGO TIANGUISTENCO", division: 7128 },
        { nombre: "SK ATLACOMULCO", division: 7129 },
        { nombre: "SK ZITACUARO", division: 7130 },
        { nombre: "SK IXTLAHUACA", division: 7150 },
        { nombre: "SKM ZITACUARO", division: 7305 },
        { nombre: "SKM VALLE DE BRAVO AV TOLUCA", division: 7306 },
        { nombre: "SKM SAN PEDRO", division: 7308 },
        { nombre: "SKM SAN MATEO", division: 7309 },
        { nombre: "SKM LEANDRO VALLE", division: 7319 },
        { nombre: "SKM FIDEL VELAZQUEZ", division: 7323 },
        { nombre: "SKM VALLE DE BRAVO", division: 7324 },
        { nombre: "SKM XONACATLAN", division: 7331 },
        { nombre: "SKM ALAMEDA", division: 7332 },
        { nombre: "SKM GALERIAS", division: 7333 },
        { nombre: "SKM METEPEC", division: 7334 },
        { nombre: "SKM LERDO", division: 7335 },
        { nombre: "SKM XINANTECATL", division: 7337 },
        { nombre: "SKM SEMINARIO", division: 7338 },
        { nombre: "SKM ATLACOMULCO", division: 7339 },
        { nombre: "SKM SANTIAGO MILTEPEC", division: 7344 },
        { nombre: "SKM TENANGO", division: 7346 },
        { nombre: "SKM IXTLAHUACA", division: 7347 },
      ],
      listaSucursalesSurtitienda: [
        { division: 7511, nombre: "ST FIDEL VELAZQUEZ" },
        { division: 7512, nombre: "ST TENANCINGO" },
        { division: 7513, nombre: "ST CENTRAL DE ABASTOS D.F" },
        { division: 7514, nombre: "ST CENTRAL DE ABASTOS TOLUCA" },
        { division: 7515, nombre: "ST IXTLAHUACA" },
        { division: 7517, nombre: "ST ZITACUARO" },
      ],
      listaSucursalesAcropolis: [
        { division: 7702, nombre: "ACROPOLIS TOLUCA CENTRO" },
        { division: 7703, nombre: "ACROPOLIS IXTLAHUACA" },
      ],
      sucursalesFiltradas: [],
      nombreEmpledo: "",
      sucursalEmpledo: "",
      rolEmpledo: "",
      headersTabla: [
        "Id",
        "Nombre",
        "Correo",
        "Division",
        "Rol",
        "RolApp",
        "Acciones",
      ],
      campos: [
        { tipo: "texto", clave: "id" },
        { tipo: "texto", clave: "nombre" },
        { tipo: "texto", clave: "correo" },
        { tipo: "texto", clave: "division" },
        { tipo: "texto", clave: "rol" },
        { tipo: "texto", clave: "rolApp" },
        { tipo: "boton", funcion: "edicionRapida" },
      ],
      usuariosIniciales: [],
      usuariosConsultados: [],
      usuariosFiltrados: [],
    };
  },
  components: {
    TablaLista,
  },
  created() {
    this.consultarUsuarios();
  },
  computed: {
    ...mapState("validacion", ["preregistros"]),
    ...mapState("autenticacion", ["usuarioActual", "usuarios"]),
    mostrarFiltradoSucursales() {
      return this.division != "";
    },
  },
  methods: {
    ...mapActions("autenticacion", [
      "consultarUsuarios",
      "guardarDatosNuevoUsuario",
    ]),
    mostrarAlertaEdicionRapida(dataEmpleado) {
      console.log(dataEmpleado);
      this.$swal({
        title: "Edicion rapida",
        html: `
    <div class="mb-3">
      <label for="actNombre">Nombre</label>
      <input type="text" id="actNombre" class="swal2-input d-block m-auto" placeholder="Nombre" value="${
        dataEmpleado.nombre
      }">
    </div>
    <div class="mb-3">
      <label for="actSucursal">Sucursal</label>
      <input type="text" id="actSucursal" class="swal2-input  d-block m-auto" placeholder="Sucursal" value="${
        dataEmpleado.division == "-" ? "" : dataEmpleado.division
      }">
    </div>
    <div class="mb-3">
      <label for="actRol">Rol</label>
      <select id="actRol" class="swal2-select d-block m-auto">
        <option value="" ${
          dataEmpleado.rol === "-" ? "selected" : ""
        }>Sin rol</option>
        <option value="gerente" ${
          dataEmpleado.rol === "gerente" ? "selected" : ""
        }>Gerente</option>
        <option value="sucursal" ${
          dataEmpleado.rol === "sucursal" ? "selected" : ""
        }>Sucursal</option>
        <option value="sistemas" ${
          dataEmpleado.rol === "sistemas" ? "selected" : ""
        }>Sistemas</option>
        <option value="cajera" ${
          dataEmpleado.rol === "cajera" ? "selected" : ""
        }>Cajera</option>
        <option value="supervisor" ${
          dataEmpleado.rol === "supervisor" ? "selected" : ""
        }>Supervisor</option>
      </select>
    </div>
    <div class="mb-3">
      <label for="actRolApp">RolApp</label>
      <select id="actRolApp" class="swal2-select d-block m-auto">
        <option value="" ${
          dataEmpleado.rolApp === "-" ? "selected" : ""
        }>Sin rol</option>
        <option value="Administrador" ${
          dataEmpleado.rolApp === "Administrador" ? "selected" : ""
        }>Administrador</option>
        <option value="Sucursal" ${
          dataEmpleado.rolApp === "Sucursal" ? "selected" : ""
        }>Sucursal</option>
      </select>
    </div>
  `,
        showCancelButton: true,
        confirmButtonText: "Actualizar",
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(document.getElementById("actNombre"));
          console.log(document.getElementById("actSucursal"));
          console.log(document.getElementById("actRol"));
          console.log(document.getElementById("actRolApp"));
          const nombre = document.getElementById("actNombre").value;
          const sucursal = document.getElementById("actSucursal").value;
          const rol = document.getElementById("actRol").value;
          const rolApp = document.getElementById("actRolApp").value;

          const usuarioEncontrado = this.usuariosIniciales.find((usuario) => {
            return usuario.uid == dataEmpleado.id;
          });

          console.log(usuarioEncontrado);
          // console.log(rol);
          // console.log(sucursal);
          // console.log(nombre);
          this.actualizarDatosEmpleado(
            nombre,
            sucursal,
            rol,
            usuarioEncontrado.uid,
            usuarioEncontrado.email,
            usuarioEncontrado.hashedPassword,
            rolApp
          );
        }
      });
    },
    async actualizarDatosEmpleado(
      nombre,
      sucursal,
      rol,
      uid,
      correo,
      contraseñaEncriptada,
      rolApp
    ) {
      try {
        console.log(nombre);
        console.log(sucursal);
        console.log(rol);
        console.log(uid);
        console.log(correo);
        console.log(contraseñaEncriptada);
        const res = await this.guardarDatosNuevoUsuario({
          uid: uid,
          nombre: nombre,
          correo: correo,
          contraseña: contraseñaEncriptada,
          rol: rol,
          rolApp: rolApp,
          sucursal: sucursal,
        });

        this.$swal({
          icon: "success",
          type: "success",
          title: "Usuario actualizado!",
          allowOutsideClick: false,
          confirmButtonText: "Continuar",
        }).then((result) => {

        });

        console.log(res);
      } catch (error) {
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      }
    },
    filtrarUsuariosNombre(nombre) {
      this.usuariosFiltrados = this.usuariosConsultados.filter(
        (preregistro) => {
          console.log(preregistro);
          if (
            preregistro?.nombre.toLowerCase().includes(nombre.toLowerCase())
          ) {
            return preregistro;
          }
        }
      );

      this.usuariosFiltrados = this.usuariosFiltrados.sort((a, b) => {
        if (a.nombre < b.nombre) {
          return 1;
        } else if (a.nombre > b.nombre) {
          return -1;
        } else {
          return 0;
        }
      });
    },
    filtrarUsuariosCorreo(correo) {
      this.usuariosFiltrados = this.usuariosConsultados.filter(
        (preregistro) => {
          console.log(preregistro);
          if (
            preregistro?.correo.toLowerCase().includes(correo.toLowerCase())
          ) {
            return preregistro;
          }
        }
      );

      this.usuariosFiltrados = this.usuariosFiltrados.sort((a, b) => {
        if (a.correo < b.correo) {
          return 1;
        } else if (a.correo > b.correo) {
          return -1;
        } else {
          return 0;
        }
      });
    },
    filtrarUsuariosRol(rol) {
      this.usuariosFiltrados = this.usuariosConsultados.filter(
        (preregistro) => {
          console.log(preregistro);
          if (preregistro?.rol.toLowerCase().includes(rol.toLowerCase())) {
            return preregistro;
          }
        }
      );

      this.usuariosFiltrados = this.usuariosFiltrados.sort((a, b) => {
        if (a.rol < b.rol) {
          return 1;
        } else if (a.rol > b.rol) {
          return -1;
        } else {
          return 0;
        }
      });
    },
    filtrarUsuariosDivison(division) {
      this.usuariosFiltrados = this.usuariosConsultados.filter(
        (preregistro) => {
          console.log(preregistro);
          if (
            preregistro?.division.toString() &&
            division
              .toString()
              .split("|")
              .some((subcadena) =>
                preregistro.division.toString().includes(subcadena)
              )
          ) {
            return preregistro;
          }

          if (this.division === "71|73") {
            this.sucursalesFiltradas = this.listaSucursalesSk;
            if (
              preregistro?.division.toString() &&
              division
                .toString()
                .split("|")
                .some((subcadena) =>
                  preregistro.division.toString().includes(subcadena)
                ) &&
              preregistro?.division
                .toString()
                .includes(this.sucursal.toString())
            ) {
              return preregistro;
            }
          } else if (this.division === "75") {
            this.sucursalesFiltradas = this.listaSucursalesSurtitienda;
            if (
              preregistro?.division.toString() &&
              division
                .toString()
                .split("|")
                .some((subcadena) =>
                  preregistro.division.toString().includes(subcadena)
                ) &&
              preregistro?.division
                .toString()
                .includes(this.sucursal.toString())
            ) {
              return preregistro;
            }
          } else if (this.division === "77") {
            this.sucursalesFiltradas = this.listaSucursalesAcropolis;
            if (
              preregistro?.division.toString() &&
              division
                .toString()
                .split("|")
                .some((subcadena) =>
                  preregistro.division.toString().includes(subcadena)
                ) &&
              preregistro?.division
                .toString()
                .includes(this.sucursal.toString())
            ) {
              return preregistro;
            }
          } else if (this.division === "") {
            if (
              preregistro?.division.toString() &&
              division
                .toString()
                .split("|")
                .some((subcadena) =>
                  preregistro.division.toString().includes(subcadena)
                ) &&
              preregistro?.division
                .toString()
                .includes(this.sucursal.toString())
            ) {
              return preregistro;
            }
          } else {
            this.sucursalesFiltradas = [];
          }
        }
      );

      this.usuariosFiltrados = this.usuariosFiltrados.sort((a, b) => {
        if (a.division < b.division) {
          return 1;
        } else if (a.division > b.division) {
          return -1;
        } else {
          return 0;
        }
      });
    },
  },
  watch: {
    usuarios(nuevosUsuarios) {
      console.log("nuevosUsuarios");
      this.usuariosConsultados = [];

      if (nuevosUsuarios) {
        console.log("SI HAY");
        for (const [id, usuario] of Object.entries(nuevosUsuarios)) {
          let usuarioUid = usuario;
          usuarioUid["uid"] = id;
          this.usuariosIniciales.push(usuarioUid);
          const objetoUsuario = {
            id: id,
            nombre: usuario["name"],
            correo: usuario["email"],
            division:
              usuario["customData"] && usuario["customData"]["Division"]
                ? usuario["customData"]["Division"]
                : "-",
            rol:
              usuario["customData"] && usuario["customData"]["rol"]
                ? usuario["customData"]["rol"]
                : "-",
            rolApp:
              usuario["customData"] && usuario["customData"]["rolApp"]
                ? usuario["customData"]["rolApp"]
                : "-",
          };
          this.usuariosConsultados.push(objetoUsuario);
        }
        console.log(this.usuariosConsultados);
        this.usuariosFiltrados = this.usuariosConsultados;
        this.filtrarUsuariosNombre(this.nombre);
        this.filtrarUsuariosCorreo(this.correo);
        this.filtrarUsuariosRol(this.rol);
      } else {
        console.log("NO HAY");
        this.usuariosConsultados = [];
        this.usuariosFiltrados = this.usuariosConsultados;
      }
    },
  },
};
</script>
<template>
  {{ nombreEmpledo }}
  <Breadcrumbs title="Consulta de usuarios" />

  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <div class="row">
          <div class="col-lg-4 col-12 mb-3">
            <label>Filtrado por nombre:</label>
            <input
              class="form-control"
              id="nombre"
              type="text"
              aria-describedby="nombre"
              placeholder="Nombre"
              v-model.trim="nombre"
              @input="filtrarUsuariosNombre(nombre)"
            />
          </div>
          <div class="col-lg-4 col-12 mb-3">
            <label>Filtrado por Correo:</label>
            <input
              class="form-control"
              id="correo"
              type="text"
              aria-describedby="correo"
              placeholder="Correo"
              v-model.trim="correo"
              @input="filtrarUsuariosCorreo(correo)"
            />
          </div>
          <div class="col-lg-4 col-12 mb-3">
            <label>Filtrado por Rol:</label>
            <select
              class="form-select"
              id="rol"
              aria-describedby="rol"
              v-model.trim="rol"
              @change="filtrarUsuariosRol(rol)"
            >
              <option value="">TODOS</option>
              <option value="gerente">Gerente</option>
              <option value="supervisor">Supervisor</option>
              <option value="cajera">Cajera</option>
              <option value="sucursal">Sucursal</option>
              <option value="sistemas">Sistemas</option>
              <option value="operaciones">Operaciones</option>
              <option value="finanzas">Finanzas</option>
              <option value="promotor">Promotor</option>
            </select>
          </div>
          <div class="col-lg-4 col-12 mb-3">
            <label>Seleccione un Formato:</label>
            <select
              class="form-select"
              id="division"
              aria-describedby="division"
              v-model.trim="division"
              @change="filtrarUsuariosDivison(division)"
            >
              <option value="">General</option>
              <option value="71|73">Super Kompras</option>
              <option value="75">Surtitienda</option>
              <option value="77">Acropolis</option>
            </select>
          </div>
          <div class="col-lg-4 col-12 mb-3">
            <div v-if="mostrarFiltradoSucursales">
              <label>Seleccione una Sucursal:</label>
              <select
                class="form-select"
                id="sucursal"
                aria-describedby="sucursal"
                v-model.trim="sucursal"
                @change="filtrarUsuariosDivison(sucursal)"
              >
                <option :value="division">TODOS</option>
                <option
                  v-for="sucursal in sucursalesFiltradas"
                  :key="sucursal.division"
                  :value="sucursal.division"
                >
                  {{ sucursal.nombre }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <TablaLista
          :data="usuariosFiltrados"
          :headers="headersTabla"
          :search="false"
          :campos="campos"
          :elementosPagina="25"
          @editarDatosEmpleado="mostrarAlertaEdicionRapida"
        ></TablaLista>
      </div>
    </div>
  </div>
</template>
<style>
.custom-input {
  width: 100%;
}
</style>
