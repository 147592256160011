<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

import TablaLista from "../../components/tablaLista.vue";
export default {
  data() {
    return {
      fechaFiltradoPedidos: [],
      folio: "",
      headersTabla: [
        "Folio",
        "Sucursal",
        "Nombre",
        "Apellido paterno",
        "Apellido materno",
        "Email",
        "Teléfono",
        "Fecha y hora",
        "Fecha de nacimiento",
        "Código postal",
        "Acciones",
      ],
      campos: [
        { tipo: "texto", clave: "folio" },
        { tipo: "texto", clave: "sucursal" },
        { tipo: "texto", clave: "nombre" },
        { tipo: "texto", clave: "apellidoPaterno" },
        { tipo: "texto", clave: "apellidoMaterno" },
        { tipo: "texto", clave: "email" },
        { tipo: "texto", clave: "telefono" },
        { tipo: "texto", clave: "fechaHora" },
        { tipo: "texto", clave: "fechanacim" },
        { tipo: "texto", clave: "cp" },
        { tipo: "boton", funcion: "entregarTarjeta" },
      ],
      preregistrosIniciales: [],
      preregistrosConsultados: [],
      preregistrosFiltrados: [],
    };
  },
  components: {
    TablaLista,
  },
  created() {
    this.cargarFechasIniciales();
  },
  computed: {
    ...mapState("validacion", ["preregistros"]),
    ...mapState("autenticacion", ["usuarioActual"]),
  },
  methods: {
    ...mapActions("validacion", [
      "consultarTarjetahabientes",
      "guardarDatosValidadosTarjetahabiente",
      "consultarConteoCli",
      "guardarDatosTarjetahabienteHasura",
      "buscarTarjetahabienteFirebase",
      "consultarTarjetahabienteHasura",
      "actualizarDatosTarjetahabienteHasura",
      "borrarPreregistro",
      "buscarTarjeta"
    ]),
    filtrarPreregistrosFolio(folio) {
      this.preregistrosFiltrados = this.preregistrosConsultados.filter(
        (preregistro) => {
          console.log(preregistro);
          if (preregistro?.folio.includes(folio)) {
            return preregistro;
          }
        }
      );

      this.preregistrosFiltrados = this.preregistrosFiltrados.sort((a, b) => {
        if (a.folio < b.folio) {
          return 1;
        } else if (a.folio > b.folio) {
          return -1;
        } else {
          return 0;
        }
      });
    },
    // ...mapActions("comercio", ["consultarPedidos"]),
    cargarFechasIniciales() {
      const fechaActual = moment();
      const fechaActualSinFecha = fechaActual.format("YYYY-MM-DD");
      const fechaActualformato = moment(fechaActualSinFecha);
      const fechaActualTimestamp = fechaActualformato.format("x");
      const fecha7diasAnteriores =
        parseInt(fechaActualTimestamp) - 6 * 86400000;
      const fechaActualDia =
        parseInt(fechaActualTimestamp) + 23 * 3600000 + 59 * 60000;
      const fechaInicio = moment(fecha7diasAnteriores).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      const fechaFin = moment(fechaActualDia).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      this.fechaFiltradoPedidos = [fechaInicio, fechaFin];
      console.log(this.fechaFiltradoPedidos);
      // this.consultarPedidos(this.fechaFiltradoPedidos);
    },
    // async verDetallePedido(elemento) {
    //   let pedidoSeleccionado = this.pedidos[elemento.id];
    //   pedidoSeleccionado['id'] = elemento.id;
    //   localStorage.setItem('detallePedido',JSON.stringify(pedidoSeleccionado));
    //   this.$router.push(`/detalle-pedido/${elemento.id}`);
    //   // this.$swal({
    //   //   title: "¡Producto agregado con éxito!",
    //   //   text: `${elemento?.nombre}`,
    //   //   icon: "success",
    //   //   confirmButtonText: "Continuar",
    //   //   confirmButtonColor: "#10B981"
    //   // })
    // },
    entregarTarjeta(dataPreregistro) {
      console.log(dataPreregistro);
      this.$swal({
        text: "Escriba un número de tarjeta:",
        input: "text",
        showDenyButton: true,
        denyButtonColor: "#d33",
        allowOutsideClick: false,
        confirmButtonColor: "var(--theme-deafult)",
        denyButtonText: "Cancelar",
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          this.validarTarjeta(result.value, dataPreregistro);
        } else {
          // this.mostrarAlertaConfirmarTelefono();
        }
      });
    },
    validarTarjeta(tarjeta, dataPreregistro) {
      const errores = [];

      if (isNaN(tarjeta) || tarjeta.length != 13) {
        errores.push({ msg: "Número de tarjeta inválido" });
      }

      console.log(errores);
      if (errores.length > 0) {
        this.$swal({
          icon: "warning",
          type: "warning",
          title: "Oops...",
          text: errores[0].msg,
        }).then((result) => {
          this.entregarTarjeta();
        });
      } else {
        this.guardarTarjetahabiente(dataPreregistro, tarjeta);
      }
    },
    async guardarTarjetahabiente(dataPreregistro, tarjeta) {
      try {
        console.log(this.preregistrosIniciales);
        const preregistroTarjetahabiente = this.preregistrosIniciales.find(
          (preregistro) => {
            return dataPreregistro.folio == preregistro.folio;
          }
        );

        console.log(preregistroTarjetahabiente);

        const fechaActual = moment();
        const formatoFechaActual = fechaActual.format("YYYY-MM-DD");

        let id_cli = "";

        id_cli = (await this.consultarConteoCli()) + 1;

        const dataTarjetahabiente = {
          apellidoM: preregistroTarjetahabiente["apellidoMaterno"],
          apellidoP: preregistroTarjetahabiente["apellidoPaterno"],
          dioalta:
            this.usuarioActual && !this.cliente
              ? this.usuarioActual.nombre
              : "",
          email: preregistroTarjetahabiente["email"],
          fechanacim: preregistroTarjetahabiente["fechanacim"],
          nombre: preregistroTarjetahabiente["nombre"],
          saldoc: 0,
          sexo: preregistroTarjetahabiente["sexo"],
          tarjeta: tarjeta,
          telefono: preregistroTarjetahabiente["telefono"],
          verificoEmail: preregistroTarjetahabiente["verificoEmail"],
          cp: preregistroTarjetahabiente["cp"],
          fechaaltat: formatoFechaActual,
          promotor: this.usuarioActual?.rol == "promotor" ? true : false,
          division: preregistroTarjetahabiente["sucursal"],
          userID: this.usuarioActual?.uid,
          id_cli: id_cli,
        };

        console.log(dataTarjetahabiente);

        const res = await this.guardarDatosValidadosTarjetahabiente(
          dataTarjetahabiente
        );

        await this.guardarTarjetahabienteHasura(tarjeta);

        await this.borrarPreregistro(dataPreregistro.folio);
        
        await this.consultarTarjeta(tarjeta);

        console.log(res);
        this.$swal({
          icon: "success",
          type: "success",
          title: "Datos actualizados correctamente!",
          allowOutsideClick: false,
          confirmButtonText: "Continuar",
        }).then((result) => {
          this.$router.push("/verificar");
          // console.log(result);
          // if (result.value) {
          //   if (this.altaTarjeta) {
          //     this.$router.replace("alta-tarjeta");
          //   } else {
          //     this.$router.push(this.urlSiguiente);
          //   }
          // }
        });
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      }
    },
    async guardarTarjetahabienteHasura(id) {
      const dataTarjetahabiente = await this.buscarTarjetahabienteFirebase(id);

      console.log(dataTarjetahabiente);
      if (dataTarjetahabiente) {
        const dataTarjetahabienteHasura = {
          Record_Id: dataTarjetahabiente["tarjeta"],
          apellidoM: dataTarjetahabiente["apellidoM"],
          apellidoP: dataTarjetahabiente["apellidoP"],
          dioalta: dataTarjetahabiente["dioalta"],
          email: dataTarjetahabiente["email"],
          fechanacim: dataTarjetahabiente["fechanacim"],
          nombre: dataTarjetahabiente["nombre"],
          saldoc: dataTarjetahabiente["saldoc"]
            ? parseFloat(dataTarjetahabiente["saldoc"])
            : 0.0,
          sexo: dataTarjetahabiente["sexo"],
          tarjeta: dataTarjetahabiente["tarjeta"],
          telefono: parseFloat(dataTarjetahabiente["telefono"]),
          verificoemail: dataTarjetahabiente["verificoEmail"]
            ? "true"
            : "false",
          cp: parseInt(dataTarjetahabiente["cp"]),
          fechaalta: dataTarjetahabiente["fechaaltat"],
          promotor: dataTarjetahabiente["promotor"] ? "true" : "false",
          division: parseInt(dataTarjetahabiente["division"]),
          userID: dataTarjetahabiente["userIDTimestamp"].slice(0, -13),
          // fechaverfi: "1999-01-01",
          fechaverfi: dataTarjetahabiente["fechaverif"],
          actcte: "true",
          id_cli: dataTarjetahabiente["id_cli"]
            ? parseInt(dataTarjetahabiente["id_cli"])
            : id_cli,
        };

        console.log("DATA TARJETAHABIENTE HASURA");
        console.log(dataTarjetahabienteHasura);

        const tarjetahabienteEncontrado =
          await this.consultarTarjetahabienteHasura(id);
        console.log("HOLA");
        console.log(tarjetahabienteEncontrado);

        if (tarjetahabienteEncontrado) {
          console.log("OP2");
          const responseActualizarHasura =
            await this.actualizarDatosTarjetahabienteHasura(
              dataTarjetahabienteHasura
            );

          console.log(responseActualizarHasura);
        } else {
          console.log("OP1");
          const responseGuardarHasura =
            await this.guardarDatosTarjetahabienteHasura(
              dataTarjetahabienteHasura
            );

          console.log(responseGuardarHasura);
        }
      }
    },
    async consultarTarjeta(tarjeta) {
      try {
        let tarjetaEncontrada;

        tarjetaEncontrada = await this.buscarTarjeta(tarjeta);

        
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      }
    },
  },
  watch: {
    preregistros(nuevosPreregistros) {
      console.log("nuevosPreregistros");
      this.preregistrosConsultados = [];

      if (nuevosPreregistros) {
        console.log("SI HAY");
        for (const [id, preregistro] of Object.entries(nuevosPreregistros)) {
          this.preregistrosIniciales.push(preregistro);
          // const fechaSegundos = pedido["TimeSolicitud"] / 1000;
          // const fechaFormato = moment
          //   .unix(fechaSegundos)
          //   .format("DD/MM/YYYY, h:mm A");
          const objetoPreregistro = {
            folio: preregistro["folio"],
            sucursal: preregistro["sucursal"],
            nombre: preregistro["nombre"],
            apellidoPaterno: preregistro["apellidoPaterno"],
            apellidoMaterno: preregistro["apellidoMaterno"],
            email: preregistro["email"],
            telefono: preregistro["telefono"],
            fechaHora: preregistro["fechaPreregistro"],
            fechanacim: preregistro["fechanacim"],
            cp: preregistro["cp"],
          };
          this.preregistrosConsultados.push(objetoPreregistro);
        }
        console.log(this.preregistrosConsultados);
        this.preregistrosFiltrados = this.preregistrosConsultados;
      } else {
        console.log("NO HAY");
        this.preregistrosConsultados = [];
        this.preregistrosFiltrados = this.preregistrosConsultados;
      }
      this.filtrarPreregistrosFolio(this.folio);
    },
    fechaFiltradoPedidos(fechas) {
      this.consultarTarjetahabientes(fechas);
    },
  },
};
</script>
<template>
  <Breadcrumbs title="Pre registros" />

  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <div class="row">
          <div class="col-lg-4 col-12 mb-3">
            <label>Filtrado por folio:</label>
            <input
              class="form-control"
              id="folio"
              type="number"
              aria-describedby="folio"
              placeholder="Folio"
              v-model.trim="folio"
              @input="filtrarPreregistrosFolio(folio)"
            />
          </div>
          <div class="col-lg-4 col-0"></div>
          <div class="col-lg-4 col-12 mb-3">
            <label>Filtrado por fecha:</label>
            <datepicker
              class="datepicker-here digits"
              range
              id="fechaPedidos"
              :enable-time-picker="false"
              type="date"
              locale="es"
              format="dd/MM/yyyy"
              select-text="Confirmar"
              cancel-text="Cancelar"
              v-model="fechaFiltradoPedidos"
            />
          </div>
        </div>
      </div>
      <div class="card-body">
        <TablaLista
          :data="preregistrosFiltrados"
          :headers="headersTabla"
          :search="false"
          :campos="campos"
          :listaEstatus="listaEstatus"
          @entregarTarjeta="entregarTarjeta"
        ></TablaLista>
      </div>
    </div>
  </div>
</template>
