<template>
    <FormularioRegistroMascotas />
  </template>
  <script>
  import FormularioRegistroMascotas from "../../components/formularioRegistroMascotas.vue";
  
  export default {
    components: {
      FormularioRegistroMascotas,
    },
  };
  </script>