<script>
/* eslint-disable */

export default {
  data() {
    return {
      tarjeta: "",
      cantidadMascotas: 7,
      periodos: ["Meses", "Años"],
      tiposMascota: ["Perro", "Gato", "Hamster", "Tortuga", "Pez"],
    };
  },
  mounted() {
    console.log(this.$route.params.id);
    this.tarjeta = this.$route.params.id;
  },
};
</script>
<template>
  <div class="container">
    <div class="page-title">
      <div class="row">
        <div class="col">
          <h3>Alta de mascotas</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-flex justify-content-center mb-3">
    <div class="card w-100 px-1">
      <form>
        <div class="card-body">
          <div class="row">
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="tarjeta"
                >Número de tarjeta</label
              >
              <input
                disabled
                class="form-control"
                id="tarjeta"
                type="number"
                aria-describedby="tarjeta"
                placeholder="Número de tarjeta"
                v-model.trim="tarjeta"
              />
            </div>
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="cantidadMascotas"
                >Cantidad de mascotas</label
              >
              <input
                disabled
                class="form-control"
                id="cantidadMascotas"
                type="number"
                aria-describedby="cantidadMascotas"
                placeholder="Cantidad de mascotas"
                v-model.trim="cantidadMascotas"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div
      class="col-md-12 mb-3"
      v-for="mascota in cantidadMascotas"
    >
      <div class="container d-flex justify-content-center">
        <div class="card w-100 px-1">
          <form>
            <div class="card-body">
              <div class="mb-3">
                <label class="col-form-label pt-0" for="nombre">Nombre</label>
                <input
                  class="form-control"
                  id="nombre"
                  type="text"
                  aria-describedby="nombre"
                  placeholder="Introduce el nombre de tu mascota"
                />
              </div>

              <div class="row align-items-end">
                <div class="col-lg col-md-12 mb-3">
                  <label class="col-form-label pt-0" for="edad">Edad</label>
                  <input
                    class="form-control"
                    id="edad"
                    type="number"
                    aria-describedby="edad"
                    placeholder="Introduce la edad de tu mascota"
                  />
                </div>
                <div class="col-lg col-md-12 mb-3">
                  <select class="form-select digits" id="periodo">
                    <option v-for="periodo in periodos">{{ periodo }}</option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-lg col-md-12 mb-3">
                  <label class="col-form-label pt-0" for="tipo"
                    >Tipo de mascota</label
                  >

                  <select class="form-select digits" id="tipo">
                    <option v-for="tipo in tiposMascota">{{ tipo }}</option>
                  </select>
                </div>
              </div>
            </div>

            <!-- <div class="card-footer text-end">
                  <button class="btn btn-secondary me-2" type="button">Cancelar</button>
                  <button class="btn btn-primary" type="submit">Continuar</button>
                </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="container mb-3">
      <div class="card w-100">
    
        <div class="card-body text-center">
          
          <button class="btn btn-primary px-5" type="submit">Guardar</button>
        </div>
      </div>
  </div>
</template>
