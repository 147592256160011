<script>
/* eslint-disable */
import { md5 } from "js-md5";
import { mapActions } from "vuex";
export default {
  props: ["urlSiguiente"],
  data() {
    return {
      contraseñaOculta: true,
      confirmarContraseñaOculta: true,
      nombre: "",
      contraseña: "",
      confirmarContraseña: "",
      correo: "",
      rol: "",
      rolApp: "",
      botonDesactivado: false,
      tipoSucursal: "",
      sucursal: "",
      listaTipoSucursales: ["Super Kompras", "Surtitienda", "Acropolis"],
      listaSucursalesSk: [
        { nombre: "SK LAS MARINAS", division: 7104 },
        { nombre: "SK LERMA", division: 7105 },
        { nombre: "SK TOLLOCAN", division: 7120 },
        { nombre: "SK PINO SUAREZ", division: 7121 },
        { nombre: "SK TENANCINGO", division: 7122 },
        { nombre: "SK SAN BUENA VENTURA", division: 7125 },
        { nombre: "SK CENTRO", division: 7126 },
        { nombre: "SK LA ASUNCION", division: 7127 },
        { nombre: "SK SANTIAGO TIANGUISTENCO", division: 7128 },
        { nombre: "SK ATLACOMULCO", division: 7129 },
        { nombre: "SK ZITACUARO", division: 7130 },
        { nombre: "SK IXTLAHUACA", division: 7150 },
      ],
      listaSucursalesSurtitienda: [
        { division: 7511, nombre: "ST FIDEL VELAZQUEZ" },
        { division: 7512, nombre: "ST TENANCINGO" },
        { division: 7513, nombre: "ST CENTRAL DE ABASTOS D.F" },
        { division: 7514, nombre: "ST CENTRAL DE ABASTOS TOLUCA" },
        { division: 7515, nombre: "ST IXTLAHUACA" },
        { division: 7517, nombre: "ST ZITACUARO" },
      ],
      listaSucursalesAcropolis: [
        { division: 7702, nombre: "ACROPOLIS TOLUCA CENTRO" },
        { division: 7703, nombre: "ACROPOLIS IXTLAHUACA" },
      ],
      listaSucursales: [],
    };
  },
  created() {},
  methods: {
    ...mapActions("autenticacion", [
      "crearNuevoUsuario",
      "guardarDatosNuevoUsuario",
    ]),
    mostrarOcultarContraseña() {
      this.contraseñaOculta = !this.contraseñaOculta;
    },
    mostrarOcultarConfirmarContraseña() {
      this.confirmarContraseñaOculta = !this.confirmarContraseñaOculta;
    },
    validarFormularioVerificacion() {
      this.botonDesactivado = true;
      const errores = [];
      const patronCorreo =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      if (this.nombre == "") {
        errores.push({ msg: "Proporcione un nombre" });
      }
      if (this.correo == "") {
        errores.push({ msg: "Proporcione un correo" });
      }
      if (this.contraseña == "") {
        errores.push({ msg: "Proporcione una contraseña" });
      }
      if (this.contraseña.length < 6) {
        errores.push({ msg: "Contraseña debe ser mayor a 6 caracteres" });
      }
      if (this.confirmarContraseña != this.contraseña) {
        errores.push({ msg: "Las contraseñas no coinciden" });
      }
      if (!patronCorreo.test(this.correo)) {
        errores.push({ msg: "Correo inválido" });
      }

      if (this.sucursal == "") {
        errores.push({
          msg: "Selecciona una sucursal",
        });
      }

      if (this.rol == "") {
        errores.push({
          msg: "Selecciona un rol",
        });
      }

      console.log(errores);
      if (errores.length > 0) {
        this.$swal({
          icon: "error",
          type: "warning",
          title: "Oops...",
          text: errores[0].msg,
        });
        this.botonDesactivado = false;
      } else {
        // this.mostrarAlertaNoCorreo();
        this.procesarDatosValidados();
      }
    },
    async procesarDatosValidados() {
      console.log("PASO VALIDACION");
      try {
        const uid = await this.crearNuevoUsuario({
          correo: this.correo,
          contraseña: this.contraseña,
          nombre: this.nombre,
        });

        const contraseñaEncriptada = md5(this.contraseña);

        const res = await this.guardarDatosNuevoUsuario({
          uid: uid,
          nombre: this.nombre,
          correo: this.correo,
          contraseña: contraseñaEncriptada,
          rol: this.rol,
          sucursal: this.sucursal,
          rolApp: this.rolApp,
        });

        this.$swal({
          icon: "success",
          type: "success",
          title: "Usuario creado exitosamente!",
          allowOutsideClick: false,
          confirmButtonText: "Continuar",
        }).then((result) => {
          this.resetearCampos();
        });

        console.log(res);
      } catch (error) {
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      } finally {
        this.botonDesactivado = false;
      }
    },
    cancelarVerificacion() {
      this.resetearCampos();
    },

    resetearCampos() {
      this.nombre = "";
      this.telefono = "";
      this.correo = "";
      this.rol = "";
      this.rolApp = "";
      this.sexo = "";
      this.tipoSucursal = "";
      this.sucursal = "";
      this.contraseña = "";
      this.confirmarContraseña = "";
      this.contraseñaOculta = true;
      this.confirmarContraseñaOculta = true;
    },
  },
  watch: {
    tipoSucursal(nuevoTipoSucursal) {
      switch (nuevoTipoSucursal) {
        case "Super Kompras":
          this.listaSucursales = this.listaSucursalesSk;
          break;
        case "Surtitienda":
          this.listaSucursales = this.listaSucursalesSurtitienda;
          break;
        case "Acropolis":
          this.listaSucursales = this.listaSucursalesAcropolis;
          break;
      }
      this.sucursal = "";
    },
  },
};
</script>
<template>
  <div class="container">
    <div class="page-title">
      <div class="row">
        <div class="col">
          <h3>Alta de usuarios</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-flex justify-content-center">
    <div class="card w-100 px-1" style="max-width: 900px">
      <form @submit.prevent="validarFormularioVerificacion()">
        <div class="card-body">
          <div class="mb-3">
            <label class="col-form-label pt-0" for="nombre">Nombre</label>
            <input
              class="form-control"
              id="nombre"
              type="text"
              aria-describedby="nombre"
              placeholder="Introduce tu nombre"
              v-model.trim="nombre"
            />
          </div>

          <div class="mb-3">
            <label class="col-form-label pt-0" for="correo"
              >Correo electrónico</label
            >
            <input
              class="form-control"
              id="correo"
              type="text"
              aria-describedby="correo"
              placeholder="Introduce tu correo electrónico"
              v-model.trim="correo"
              :disabled="noCorreo"
            />
          </div>
          <div class="row">
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="constraseña"
                >Contraseña</label
              >
              <input
                class="form-control"
                id="contraseña"
                :type="[contraseñaOculta ? 'password' : 'text']"
                aria-describedby="contraseña"
                placeholder="Introduce tu contraseña"
                v-model.trim="contraseña"
              />
              <div class="show-hide" @click="mostrarOcultarContraseña()">
                <span :class="[contraseñaOculta ? 'show' : '']"></span>
              </div>
            </div>
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="confirmarContraseña"
                >Confirmar contraseña</label
              >
              <input
                class="form-control"
                id="confirmarContraseña"
                :type="[confirmarContraseñaOculta ? 'password' : 'text']"
                aria-describedby="confirmarContraseña"
                placeholder="Confirma tu contraseña"
                v-model.trim="confirmarContraseña"
              />
              <div
                class="show-hide"
                @click="mostrarOcultarConfirmarContraseña()"
              >
                <span :class="[confirmarContraseñaOculta ? 'show' : '']"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="rol">Rol</label>
              <select class="form-select digits" id="rol" v-model="rol">
                <option value="gerente">Gerente</option>
                <option value="supervisor">Supervisor</option>
                <option value="cajera">Cajera</option>
                <option value="sucursal">Sucursal</option>
                <option value="sistemas">Sistemas</option>
                <option value="operaciones">Operaciones</option>
                <option value="finanzas">Finanzas</option>
                <option value="promotor">Promotor</option>
              </select>
            </div>
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="rol">RolApp</label>
              <select class="form-select digits" id="rol" v-model="rolApp">
                <option value="">Sin Rol</option>
                <option value="Administrador">Administrador</option>
                <option value="Sucursal">Sucursal</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-lg col-md-12 mb-3">
              <label class="form-label" for="exampleFormControlSelect30"
                >Tipo de sucursal</label
              >
              <select
                class="form-select digits"
                id="exampleFormControlSelect30"
                v-model="tipoSucursal"
              >
                <option v-for="tipo in listaTipoSucursales">{{ tipo }}</option>
              </select>
            </div>
            <div class="col-lg col-md-12 mb-3">
              <label class="form-label" for="exampleFormControlSelect30"
                >Sucursal</label
              >
              <select
                class="form-select digits"
                id="exampleFormControlSelect30"
                v-model="sucursal"
              >
                <option
                  v-for="sucursal in listaSucursales"
                  :value="sucursal.division"
                >
                  {{ sucursal.nombre }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="card-footer text-end">
          <button
            class="btn btn-secondary me-2"
            type="button"
            @click="cancelarVerificacion()"
          >
            Cancelar
          </button>
          <!-- <button class="btn btn-primary" type="submit">Continuar</button> -->
          <button
            class="btn btn-primary"
            :disabled="botonDesactivado"
            type="submit"
          >
            <div
              class="loader-box m-0"
              style="height: 20px"
              v-if="botonDesactivado"
            >
              <div
                class="loader-2 me-2"
                style="height: 25px !important; width: 25px !important"
              ></div>
              <span>guardando...</span>
            </div>
            <span v-else>Crear usuario</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<style>
.dp__input_wrap input {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>

<!-- <div class="form-input position-relative">
  <input class="form-control" :type="[contraseñaOculta ? 'password' : 'text' ]" name="login[password]" placeholder="Introduce tu contraseña"
    v-model.trim="contraseña">
  <div class="show-hide" @click="mostrarOcultarContraseña()"><span :class="[contraseñaOculta ? 'show' : '' ]"></span></div>
</div> -->
